<template>
    <div class="p-[2rem] flex text-[#343637] flex-col items-center">
        <div class="fixed top-0 left-0 w-full h-full" v-if="isOpen" @click="toggleType"></div>
        <div class="w-full flex text-[#2196f3]">
            <router-link to="/assessments">
                <span> <font-awesome-icon class="mr-1" :icon="['fas', 'arrow-left']" />Go Back </span>
            </router-link>
        </div>
        <div v-if="isFetching" class="w-full h-[100vh] flex items-center justify-center">
            <LoaderComponentBlue />
        </div>
        <form v-else class="flex flex-col items-start justify-center gap-12 w-[50%]">
            <div class="flex flex-col items-start gap-1 w-full">
                <label for="name">Name:</label>
                <input disabled placeholder="Enter a name" v-model="assessment.name" type="text" id="name" required />
            </div>

            <div class="flex flex-col items-start gap-1 w-full">
                <label for="category">Category:</label>
                <div class="select-container">
                    <select id="category" v-model="assessment.category" required>
                        <option value="" selected disabled>{{ $t("Select a category") }}</option>
                        <option v-for="(category, index) in categoriesList" :key="index" :value="category">
                            {{ category }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="flex flex-col items-start gap-1 w-full">
                <label for="description">Description:</label>
                <textarea style="resize: none" rows="4" placeholder="Enter a description (250 max)" v-model="assessment.description_test" id="description" required></textarea>
            </div>

            <!-- Questions List -->
            <div class="w-full flex flex-col gap-2">
                <label>Questions:</label>
                <div class="w-full flex flex-col gap-4">
                    <div
                        v-for="(question, questionIndex) in assessment.questions_list"
                        :key="questionIndex"
                        class="bg-[#F4FAFF] border border-[1px] border-[#d8e2ee] shadow-md p-4 rounded-md w-full flex flex-col items-start gap-5"
                    >
                        <label class="w-full text-center font-bold text-lg">Question {{ questionIndex + 1 }}</label>
                        <div class="flex flex-col items-start gap-1 w-full">
                            <label for="question">Statement:</label>
                            <input disabled placeholder="Enter the question" v-model="question.question" type="text" id="question" required />
                        </div>
                        <div class="flex flex-col relative items-start gap-1 w-full">
                            <div v-if="isOpen" ref="filterDropdown" class="py-2 flex flex-col gap-1 rounded absolute w-[20%] shadow-xl bg-[#fff] overflow-y-scroll z-10 top-[40%] right-0">
                                <span
                                    :class="{
                                        'bg-[#C2E5FF] text-[#0D74CE]': type == 'Text',
                                    }"
                                    class="hover:bg-[#C2E5FF] cursor-pointer px-4 py-1 text-sm font-semibold"
                                    @click="
                                        type = 'Text';
                                        toggleType();
                                    "
                                    >Text</span
                                >
                                <span
                                    :class="{
                                        'bg-[#C2E5FF] text-[#0D74CE]': type == 'Image',
                                    }"
                                    class="hover:bg-[#C2E5FF] cursor-pointer px-4 py-1 text-sm font-semibold"
                                    @click="
                                        type = 'Image';
                                        toggleType();
                                    "
                                    >Image</span
                                >
                            </div>
                            <label class="flex w-full items-center justify-between" for="description-question"
                                >Description:
                                <button
                                    @click="toggleType()"
                                    class="text-gray-700 inline-flex items-center justify-center text-sm rounded min-h-[30px] px-4 py-[5px] font-m focus:outline-none focus:ring-2 focus:ring-blue-500"
                                >
                                    Type : <span class="font-bold ml-1"> {{ type }}</span> <font-awesome-icon class="ml-2" :icon="['fas', 'caret-down']" /></button
                            ></label>
                            <input disabled v-if="type == 'Text'" placeholder="Enter the question" v-model="question.description" type="text" id="description-question" />
                            <input disabled v-else class="!bg-white" id="description-question" type="file" />
                        </div>
                        <div v-if="assessment.category === 'Hard Skills'" class="flex flex-col items-start gap-1 w-full">
                            <label for="question">Code: </label>
                            <input disabled placeholder="Enter the code snippet" v-model="question.code" type="text" id="question" />
                        </div>

                        <div class="w-full flex flex-col items-start gap-1">
                            <label class="">Options:</label>
                            <div class="w-full flex flex-col gap-2">
                                <div class="flex relative items-center gap-1" v-for="(value, key) in question.options" :key="key">
                                    <span
                                        :class="{
                                            'border-green-500 !bg-green-100': assessment.answers[questionIndex + 1] == key,
                                            'border-[#d8e2ee]': assessment.answers[questionIndex + 1] != key,
                                        }"
                                        class="rounded-[7px] h-12 w-[10%] bg-white flex items-center justify-center border border-[1px] border-[#d8e2ee]"
                                    >
                                        {{ convertToAlphabet(key) }}
                                    </span>
                                    <input
                                        disabled
                                        :class="{
                                            'border-green-500 bg-green-100': assessment.answers[questionIndex + 1] == key,
                                            'border-[#d8e2ee]': assessment.answers[questionIndex + 1] != key,
                                        }"
                                        class="w-[85%]"
                                        v-model="question.options[key]"
                                        type="text"
                                        placeholder="Option text"
                                        required
                                    />
                                    <button
                                        class="absolute flex items-center justify-center text-red-500 right-[2%]"
                                        @click="removeOption(questionIndex, key)"
                                        v-if="Object.keys(question.options).length - 1 == Object.keys(question.options).indexOf(key) && Object.keys(question.options).length > 4"
                                    >
                                        <font-awesome-icon class="w-6 h-6 hover:bg-red-200 rounded-full" :icon="['far', 'circle-xmark']" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start gap-1 w-full">
                            <label>Answer:</label>
                            <div class="select-container">
                                <select v-model="assessment.answers[questionIndex + 1]" @change="updateAnswer($event, questionIndex)" required>
                                    <option value="" selected disabled>Select an answer</option>
                                    <option v-for="(value, key) in question.options" :key="key" :value="key">
                                        {{ convertToAlphabet(key) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col items-start gap-2 w-full">
                <label>Interpretations:</label>
                <div v-if="interpretations" class="flex flex-col items-start gap-4 w-full">
                    <div
                        v-for="(interpretation, index) in interpretations"
                        :key="index"
                        class="bg-[#F4FBF7] border border-[1px] border-[#d8e2ee] shadow-md p-4 rounded-md w-full flex flex-col items-start gap-5"
                    >
                        <label>Range: </label>
                        <div class="flex gap-2">
                            <input disabled class="w-[45%]" type="number" v-model.number="interpretation.range[0]" placeholder="Min score" required />
                            <input disabled class="w-[45%]" type="number" v-model.number="interpretation.range[1]" placeholder="Max score" required />
                        </div>

                        <div class="flex flex-col items-start gap-1 w-full">
                            <label>Title:</label>
                            <input disabled class="w-full" v-model="interpretation.title" type="text" placeholder="Enter title" required />
                        </div>

                        <div class="flex flex-col items-start gap-1 w-full">
                            <label>Description:</label>
                            <textarea disabled style="resize: none" rows="4" v-model="interpretation.description" placeholder="Enter description" required></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import axios from "axios";
import LoaderComponentBlue from "@/components/LoaderComponentBlue.vue";

export default {
    name: "EditAssessment",
    components: {
        LoaderComponentBlue,
    },
    data() {
        return {
            type: "Text",
            categoriesList: ["Psychometrics", "Hard Skills", "Soft Skills"],
            assessment: {
                name: "",
                category: "",
                description_test: "",
                questions_list: [
                    {
                        question_number: 1,
                        question: "",
                        description: "",
                        code: "",
                        options: {
                            1: "",
                            2: "",
                            3: "",
                            4: "",
                        },
                    },
                ],
                answers: {},
            },
            interpretations: [],
            isLoading: false,
            isFetching: true,
            isVisible: false,
            isOpen: false,
            message: "",
            bgColor: "",
        };
    },
    methods: {
        async submitForm() {
            this.isLoading = true;
            const assessmentId = this.$route.params.id;

            try {
                await axios.put(`https://server.go-platform.com/uploadAssessment/${assessmentId}`, this.assessment);

                this.isVisible = true;
                this.message = "Assessment updated successfully!";
                this.bgc = "success";
            } catch (error) {
                console.error("Request failed", error);
                this.isVisible = true;
                this.message = "Failed to update the assessment.";
                this.bgc = "error";
            } finally {
                this.isLoading = false;
            }
        },
        getInterpretation() {
            const assessmentName = this.assessment.name;
            axios
                .post(`https://server.go-platform.com/interceptions/fetchInterpretation`, { assessmentName: assessmentName })
                .then((response) => {
                    if (response.data?.interpretations?.length > 0) {
                        this.interpretations = response.data.interpretations;
                        this.interpretation_id = response.data._id;
                    } else {
                        this.interpretationsExist = false;
                        const interpretationsDefault = [
                            { range: [0, 33], title: "", description: "" },
                            { range: [34, 66], title: "", description: "" },
                            { range: [67, 100], title: "", description: "" },
                        ];
                        this.interpretations = interpretationsDefault;
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        getAssessment() {
            const assessmentId = this.$route.params.id;
            axios
                .get(`https://server.go-platform.com/uploadAssessment/${assessmentId}`)
                .then((response) => {
                    this.assessment = response.data.assessment;
                    this.isFetching = false;
                })
                .then(() => this.getInterpretation())
                .catch((error) => {
                    console.error(error);
                    this.isFetching = false;
                });
        },
        toggleType() {
            this.isOpen = !this.isOpen;
        },
        updateAnswer(event, questionIndex) {
            const selectedValue = Number(event.target.value); // Convert the selected value to a number
            this.assessment.answers = {
                ...this.assessment.answers,
                [(questionIndex + 1).toString()]: selectedValue, // Update the answers object reactively
            };
        },
        addQuestion() {
            this.assessment.questions_list.push({
                question_number: this.assessment.questions_list.length + 1,
                question: "",
                options: {
                    1: "",
                    2: "",
                    3: "",
                    4: "",
                },
            });
        },
        addOption(questionIndex) {
            const currentOptions = this.assessment.questions_list[questionIndex].options;
            const nextOptionNumber = (Object.keys(currentOptions).length + 1).toString(); // Ensure the key is a string
            // Directly set the new option
            this.assessment.questions_list[questionIndex].options[nextOptionNumber] = "";
        },
        removeOption(questionIndex, optionKey) {
            const currentOptions = this.assessment.questions_list[questionIndex].options;
            if (Object.keys(currentOptions).length > 4) {
                // Directly delete the option
                delete this.assessment.questions_list[questionIndex].options[optionKey];
            }
        },
        convertToAlphabet(key) {
            const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            return alphabet[parseInt(key) - 1]; // Convert numeric key to alphabet (1 -> A, 2 -> B, etc.)
        },
    },
    mounted() {
        this.getAssessment();
    },
};
</script>

<style scoped>
.add-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    color: #343637;
    font-family: "Roboto";
    font-style: normal;
}

.back-container {
    width: 100%;
    display: flex;
    color: #2196f3;
}

.back-container span:hover {
    text-decoration: underline;
}

label {
    margin-left: 3px;
}

input {
    width: 100%;
    padding: 0.7rem 0.5rem;
    border-radius: 7px;
    border: 1px solid #d8e2ee;
    box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
}

input:focus {
    outline-color: #2196f3;
}

textarea {
    width: 100%;
    padding: 0.7rem 0.5rem;
    border-radius: 7px;
    border: 1px solid #d8e2ee;
    box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);

    &:focus {
        outline-color: #2196f3;
    }
}

.array-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.icon {
    background: red;
    color: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    padding: 2px;
    cursor: pointer;
}

.icon:hover {
    opacity: 0.85;
}

.btn-wrapper {
    width: 100%;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.btn-wrapper a {
    width: 100%;
}

.btn-wrapper span {
    color: #2196f3;
    height: 10px;
    margin-bottom: 7px;
}

.select-container {
    width: 100%;
    padding: 0.7rem 0.1rem;
    border-radius: 7px;
    background: #fff;
    border: 1px solid #d8e2ee;
    box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
    display: flex;
    justify-content: flex-start;
}

select {
    width: 97%;
    outline: none;
    cursor: pointer;
    height: 100%;
}
</style>
