<template>
    <div class="h-[100vh] py-[10rem] items-center justify-center flex flex-col w-full space-y-5">
        <div class="flex gap-5 flex-wrap">
            <ButtonComponent :left-icon="BeakerIcon" intent="primary"> Submit </ButtonComponent>

            <ButtonComponent intent="secondary"> Secondary </ButtonComponent>
            <ButtonComponent intent="danger"> Danger </ButtonComponent>
            <ButtonComponent intent="text"> Text </ButtonComponent>
        </div>
        <div class="flex gap-5 flex-wrap">
            <ButtonComponent loading intent="primary"> Primary </ButtonComponent>
            <ButtonComponent loading intent="secondary"> Secondary </ButtonComponent>
            <ButtonComponent loading intent="danger"> Danger </ButtonComponent>
            <ButtonComponent loading intent="text"> Text </ButtonComponent>
        </div>
        <div class="flex gap-5 flex-wrap">
            <ButtonComponent disabled intent="primary"> Primary </ButtonComponent>
            <ButtonComponent disabled intent="secondary"> Secondary </ButtonComponent>
            <ButtonComponent disabled intent="danger"> Danger </ButtonComponent>
            <ButtonComponent disabled intent="text"> Text </ButtonComponent>
        </div>
    </div>
</template>

<script>
import ButtonComponent from "@/components/ReusableComponents/ButtonComponent";
//import { EnvelopeIcon } from "@heroicons/vue/20/solid"
//import { BeakerIcon } from '@heroicons/vue/24/solid'
export default {
    name: "TestButtons",
    components: {
        ButtonComponent,
        // BeakerIcon
        //  EnvelopeIcon
    },
    data() {
        return {};
    },
};
</script>
<style scoped></style>
