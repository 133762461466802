<template>
    <div class="add-container">
        <div class="back-container">
            <router-link to="/adminBlogs">
                <span> <font-awesome-icon class="mr-1" :icon="['fas', 'arrow-left']" />Go Back </span>
            </router-link>
        </div>
        <ToastNotification :message="message" :isVisible="isVisible" :bgColor="bgc" />
        <form @submit.prevent="submitForm">
            <div class="wrapper-fields">
                <label for="category">Categories</label>
                <div class="select-container">
                    <select id="category" v-model="blogPost.category" required>
                        <option value="" selected disabled>{{ $t("Select a category") }}</option>
                        <option v-for="category in categoriesList" :key="category._id" :value="category._id">
                            {{ category.category }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="wrapper-fields">
                <label for="title">Title:</label>
                <input placeholder="Enter a title" v-model="blogPost.title" type="text" id="title" required />
            </div>

            <div class="wrapper-fields">
                <label for="description">Description:</label>
                <textarea style="resize: none" rows="4" placeholder="Enter a description (250 max)" v-model="blogPost.description" id="description" required></textarea>
            </div>

            <div class="wrapper-fields">
                <label for="author">Author:</label>
                <input placeholder="Enter the author name" v-model="blogPost.author" type="text" id="author" required />
            </div>

            <div class="wrapper-fields">
                <label for="time">Time to Read:</label>
                <input placeholder="Enter a reading time (in minutes)" v-model="blogPost.reading_time" type="number" id="time" required />
            </div>

            <div class="wrapper-fields">
                <label for="text">Text (html):</label>
                <!-- <textarea style="resize: none" rows="5" placeholder="Enter a text content" v-model="blogPost.text" id="text" required></textarea> -->
                <QuillEditor theme="snow" toolbar="full" v-model:content="blogPost.text" contentType="html" class="bg-white w-full" />
            </div>
            <div class="wrapper-fields">
                <label for="image">Image (.png):</label>
                <input id="image" type="file" @change="handleImageChange" />
            </div>

            <div class="wrapper-fields">
                <label for="keywords">Keywords:</label>
                <div class="array-container" v-for="(keyword, index) in blogPost.keywords" :key="index">
                    <input id="keywords" placeholder="Enter a keyword" v-model="blogPost.keywords[index]" type="text" required />
                    <font-awesome-icon v-if="index !== 0" class="icon" @click="deleteKeyword(index)" :icon="['fas', 'minus']" />
                </div>
                <div class="btn-container">
                    <button @click="addKeyword">Add Keyword</button>
                </div>
            </div>

            <div class="wrapper-fields">
                <label for="subtopics">Subtopics:</label>
                <div class="array-container" v-for="(subtopic, index) in blogPost.subtopics" :key="index">
                    <input id="subtopics" placeholder="Enter a subtopic" v-model="blogPost.subtopics[index]" type="text" required />
                    <font-awesome-icon v-if="index !== 0" class="icon" @click="deleteSubtopic(index)" :icon="['fas', 'minus']" />
                </div>
                <div class="btn-container">
                    <button @click="addSubtopic">Add Subtopic</button>
                </div>
            </div>
            <div v-if="!isLoading" class="btn-wrapper">
                <span></span>

                <button type="submit">Submit</button>
                <router-link to="/adminBlogs"><button class="cancel-btn">Cancel</button></router-link>
            </div>
            <div v-else class="btn-wrapper">
                <span>Uploading Blog... Please wait.</span>
                <button class="loader-btn">
                    <LoaderComponent />
                </button>
                <button class="loader-btn cancel-btn">Cancel</button>
            </div>
        </form>
    </div>
</template>
<script>
import axios from "axios";
import LoaderComponent from "@/components/LoaderComponent";
import ToastNotification from "@/components/ToastNotification.vue";
import { QuillEditor } from "@vueup/vue-quill";

export default {
    name: "addBlogs",
    components: { LoaderComponent, ToastNotification, QuillEditor },
    data() {
        return {
            blogPost: {
                category: "",
                title: "",
                text: "",
                author: "",
                keywords: [""],
                subtopics: [""],
                reading_time: "",
                description: "",
                image: "",
            },
            categoriesList: [],
            isLoading: false,

            isVisible: false,
            message: "",
            bgColor: "",
        };
    },
    methods: {
        submitForm() {
            this.isLoading = true;
            let formData = new FormData();
            formData.append("category", this.blogPost.category);
            formData.append("title", this.blogPost.title);
            formData.append("text", this.blogPost.text);
            formData.append("author", this.blogPost.author);
            formData.append("reading_time", this.blogPost.reading_time);
            formData.append("description", this.blogPost.description);
            this.blogPost.keywords.forEach((keyword, index) => {
                formData.append(`keywords[${index}]`, keyword);
            });
            this.blogPost.subtopics.forEach((subtopic, index) => {
                formData.append(`subtopics[${index}]`, subtopic);
            });
            if (this.blogPost.image) {
                formData.append("image", this.blogPost.image);
            }
            axios
                .post("https://server.go-platform.com/articles/post", formData, {
                    withCredentials: true,
                })
                .then(() => {
                    this.blogPost.category = "";
                    this.blogPost.title = "";
                    this.blogPost.text = "";
                    this.blogPost.author = "";
                    this.blogPost.reading_time = "";
                    this.blogPost.description = "";
                    this.blogPost.keywords = [""];
                    this.blogPost.subtopics = [""];
                    this.blogPost.image = null;

                    // Set success message and display it
                    this.message = "Blog uploaded successfully!";
                    this.bgc = "success";
                    this.isVisible = true;
                })
                .catch((error) => {
                    console.error("Error submitting blog post", error);
                    this.message = "An error occurred while uploading the blog.";
                    this.bgc = "error";
                    this.isVisible = true;
                })
                .finally(() => (this.isLoading = false));
        },
        handleImageChange(event) {
            this.blogPost.image = event.target.files[0];
        },
        getCategories() {
            axios
                .get("https://server.go-platform.com/categories/", {
                    withCredentials: true,
                })
                .then((response) => (this.categoriesList = response.data.categories))
                .catch((err) => {
                    console.log(err);
                });
        },
        addKeyword() {
            this.blogPost.keywords.push("");
        },
        deleteKeyword(index) {
            this.blogPost.keywords.splice(index, 1);
        },
        addSubtopic() {
            this.blogPost.subtopics.push("");
        },
        deleteSubtopic(index) {
            this.blogPost.subtopics.splice(index, 1);
        },
    },
    mounted() {
        this.getCategories();
    },
};
</script>
<style scoped>
.add-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    color: #343637;
    font-family: "Roboto";
    font-style: normal;
}

.back-container {
    width: 100%;
    display: flex;
    color: #2196f3;
}

.back-container span:hover {
    text-decoration: underline;
}

form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 3rem;
    width: 70%;
}

.wrapper-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
}

label {
    margin-left: 3px;
}

input {
    width: 90%;
    padding: 0.7rem 0.5rem;
    border-radius: 7px;
    border: 1px solid #d8e2ee;
    box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
}

input:focus {
    outline-color: #2196f3;
}

textarea {
    width: 90%;
    padding: 0.7rem 0.5rem;
    border-radius: 7px;
    border: 1px solid #d8e2ee;
    box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);

    &:focus {
        outline-color: #2196f3;
    }
}

.array-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.icon {
    background: red;
    color: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    padding: 2px;
    cursor: pointer;
}

.icon:hover {
    opacity: 0.85;
}

.btn-wrapper {
    width: 90%;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.btn-wrapper a {
    width: 100%;
}

.btn-wrapper span {
    color: #2196f3;
    height: 10px;
    margin-bottom: 7px;
}

button {
    width: 100%;
    height: 60px;
    padding: 0.5rem;
    border-radius: 10px;
    border: none;
    background: #2196f3;
    font-weight: 500;
    color: #fff;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.1),
        0 4px 8px rgba(0, 0, 0, 0.1);

    &:hover {
        opacity: 0.85;
    }
}

.loader-btn {
    opacity: 0.85;
    cursor: not-allowed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cancel-btn {
    background: red !important;
}

.btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;
}

.btn-container button {
    height: 40px !important;
    background: #53b9ab;
    width: 90% !important;
}

.select-container {
    width: 90%;
    padding: 0.7rem 0.1rem;
    border-radius: 7px;
    background: #fff;
    border: 1px solid #d8e2ee;
    box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
    display: flex;
    justify-content: flex-start;
}

select {
    width: 97%;
    outline: none;
    cursor: pointer;
    height: 100%;
}
</style>
