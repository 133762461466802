<template>
    <confirmDelete :closeConfirm="closeConfirm" :isShown="isShown" :id="blogId" />
    <div :style="{ overflowY: isShown ? 'hidden' : 'visible' }" class="blogs-container">
        <div class="btn-section">
            <router-link to="/addBlogs"><button>Add +</button></router-link>
        </div>
        <table v-if="!this.Store.loadingArticles">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Author</th>
                    <th>Category</th>
                    <th>Date</th>
                    <th class="actions">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="blog in articles" :key="blog._id">
                    <td class="title-case w-1.5/5">{{ blog.title }}</td>
                    <td class="w-1/5">{{ blog.author }}</td>
                    <td class="w-1/5">{{ blog.category.category }}</td>
                    <td class="w-0.5/5">{{ getDate(blog.date) }}</td>
                    <td class="actions w-1/5">
                        <router-link :to="`/updateBlog/${blog._id}`"> <font-awesome-icon class="icons w-4 mr-1 h-4 p-2 update text-white" :icon="['fas', 'pencil']" /></router-link>
                        <font-awesome-icon class="icons w-4 info mr-1 h-4 p-2 text-white" :icon="['fas', 'circle-info']" />
                        <font-awesome-icon @click="openConfirmDelete(blog._id)" class="icons w-4 h-4 bg-red-500 p-2 text-white" :icon="['fas', 'trash']" />
                    </td>
                </tr>
            </tbody>
        </table>
        <table v-else>
            <thead>
                <tr class="loading-tr">
                    <th></th>
                </tr>
            </thead>
            <tbody class="loading-body">
                <LoaderComponentBlue />
            </tbody>
        </table>
    </div>
</template>
<script>
import { useStore } from "@/store/index";
import confirmDelete from "@/components/ConfirmDelete.vue";
import LoaderComponentBlue from "@/components/LoaderComponentBlue";

export default {
    name: "adminBlogs",
    components: { confirmDelete, LoaderComponentBlue },
    data() {
        return {
            isShown: false,
            blogId: "",
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        getDate(str) {
            const date = new Date(str);

            const options = {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            };

            const formattedDate = date.toLocaleDateString("en-US", options);
            return formattedDate;
        },

        openConfirmDelete(id) {
            this.blogId = id;
            this.isShown = true;
        },

        closeConfirm() {
            this.isShown = false;
        },
    },
    computed: {
        articles() {
            return this.Store.getArticlesAdmin;
        },
        loadingArticles() {
            return this.Store.getLoadingArticles;
        },
    },
    mounted() {
        this.Store.fetchArticlesAdmin();
    },
};
</script>
<style scoped>
.blogs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
}

.btn-section {
    width: 90%;
    display: flex;
    align-items: flex-end;
}

table {
    width: 90%;
    border: 1px solid #ddd;
}

thead {
    background: #2196f3;
    color: #fff;
}

tbody {
    background: #fff;
}

.loading-tr {
    background: #2196f3;
    height: 40px;
}

.loading-body {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

th {
    padding: 8px 10px;
    text-align: start;
    border-bottom: 1px solid #ddd;
    font-weight: 600;
    font-size: 16px;
}

.actions {
    text-align: center;
}

td {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    font-size: 15px;
}

.icons {
    cursor: pointer;

    &:hover {
        opacity: 0.85;
    }
}

.update {
    background: #5bb98b;
}

.info {
    background: #2196f3;
}

button {
    width: 100%;
    height: 40px;
    padding: 0.5rem;
    border-radius: 10px;
    border: none;
    background: #2196f3;
    font-weight: 500;
    color: #fff;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.1),
        0 4px 8px rgba(0, 0, 0, 0.1);

    &:hover {
        opacity: 0.85;
    }
}

a {
    width: 15%;
}

.title-case {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
}
</style>
